// Any variable from node_modules/bootstrap/scss/variables or src/scss/theme/_variables.scss can be overridden with your own value.

$primary: #523B9B;
$secondary: #14054A;
$success: #523B9B;
$--falcon-toast-bg: #523B9B;
$navbar-vibrant-bg-image: linear-gradient(
                -45deg,
                #523B9B,
                #14054A
);
$navbar-vertical-vibrant-bg-image: linear-gradient(
                -45deg,
                #523B9B,
                #14054A
);

// Any variable from node_modules/bootstrap/scss/variables or src/scss/theme/_variables.scss can be overridden with your own value.
