/* -------------------------------------------------------------------------- */
/*                                  Override                                  */
/* -------------------------------------------------------------------------- */
@if $enable-dark-mode {
  @include color-mode(dark, true) { 
    @each $color, $value in $theme-all-colors {
      .dark__bg-#{$color} {
        background-color: $value !important;
      }
      .dark__text-#{$color} {
        color: $value !important;
      }
      .dark__bg-card-dark{
        background-color: $card-bg-dark !important;
      }
    }

    @each $key, $value in $opacities {
      .dark__bg-opacity-#{$key} {
        --#{$prefix}bg-opacity: #{$value} !important;
      }
    }
 
    ::selection {
      background-color: var(--#{$prefix}warning);
    }

    // .btn-close{
    //   filter: invert(1) grayscale(100%) brightness(70%) !important;
    // }
  }
}