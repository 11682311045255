/* -------------------------------------------------------------------------- */
/*                               Navbar Standard                              */
/* -------------------------------------------------------------------------- */

.navbar-standard {
  font-size: map_get($font-sizes, 10);
  font-weight: $font-weight-semi-bold;

  .navbar-collapse {
    overflow-y: auto;
    max-height: calc(100vh - #{$standard-nav-height});
  }
}